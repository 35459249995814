import React from "react";

import { Icon, Table, Popup } from "semantic-ui-react";

const FeaturesTable = () => (
  <Table celled textAlign="center" unstackable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell width={8}></Table.HeaderCell>
        <Table.HeaderCell width={4}>Salesforce Identity</Table.HeaderCell>
        <Table.HeaderCell width={4}>CYM Identity</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      <Table.Row>
        <Table.Cell textAlign="left">SMS</Table.Cell>
        <Table.Cell colSpan="2">
          <Popup
            trigger={
              <Icon name="check circle outline" size="big" color="green" />
            }
          >
            You can reuse Salesforce Identity SMS with CYM Identity
          </Popup>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell textAlign="left">OTP</Table.Cell>
        <Table.Cell colSpan="2">
          <Popup
            trigger={
              <Icon name="check circle outline" size="big" color="green" />
            }
          >
            You can reuse Salesforce Identity OTP with CYM Identity
          </Popup>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell textAlign="left">Push Notifications</Table.Cell>
        <Table.Cell colSpan="2">
          <Popup
            trigger={
              <Icon name="check circle outline" size="big" color="green" />
            }
          >
            You can reuse Salesforce Identity Push Notifications with CYM Identity
          </Popup>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell textAlign="left">Biometrics</Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell>
          <Popup
            trigger={
              <Icon name="check circle outline" size="big" color="green" />
            }
          >
            Authenticate users with TouchId, FaceId, Fingerprints, Windows Hello,
            ...
          </Popup>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell textAlign="left">Custom Authenticators</Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell>
          <Popup
            trigger={
              <Icon name="check circle outline" size="big" color="green" />
            }
          >
            Bring your own passwordless authenticator
          </Popup>
        </Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
);


export default FeaturesTable;
